import React, { useContext, useEffect, useState } from "react";
import { forgotPassword, updatePassword } from "../../api/functions/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useParams, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const { token } = useParams();

  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isError, setIsError] = useState("");
  const [isSuccess, setIsSuccess] = useState("");

  const [showPassword, setShowPassword] = useState(true);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = {
      email,
    };

    try {
      const res = await forgotPassword(dataToSend);

      if (res.status === 200) {
        setIsSuccess(res.data.message.en);

        // Navigate to account/login page after 3 seconds
        setTimeout(() => {
          navigate("/account/login");
        }, 3000);
      }
    } catch (err) {
      setIsError(err?.response?.data?.message);
      setTimeout(() => {
        setIsError("");
      }, 3000);
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    const dataToSend = {
      newPassword: newPassword,
      confirmPassword: confirmNewPassword,
    };

    try {
      const res = await updatePassword(dataToSend, token);

      if (res.status === 200) {
        setIsSuccess(res.data.message.en);

        // Navigate to account/login page after 3 seconds
        setTimeout(() => {
          navigate("/account/login");
        }, 3000);
      }
    } catch (err) {
      setIsError(err?.response?.data?.message);
      setTimeout(() => {
        setIsError("");
      }, 3000);
    }
  };

  return (
    <div className="row justify-content-center">
      <h2 className="card-title text-center mb-4 text-success">
        {token ? "Update Password" : "Reset Password"}
      </h2>
      {!isSuccess ? (
        <form onSubmit={token ? handleUpdatePassword : handleForgotSubmit}>
          <div className="form-group">
            <div className="input-group">
              <label htmlFor="email">
                {token
                  ? "Enter your new password and confirm it below."
                  : "To reset your password, enter your email below and submit. An email will be sent to you with instructions about how to complete the process."}
              </label>
              {!token && (
                <input
                  type={"text"}
                  name="email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="example@domain.com"
                  className="form-control special_styling"
                />
              )}
            </div>
          </div>
          {token && (
            <>
              <div className="form-group">
                <label htmlFor="newPassword">New Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="newPassword"
                    id="newPassword"
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newPassword}
                    className="form-control special_styling"
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="confirmNewPassword">Confirm Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="confirmNewPassword"
                    id="confirmNewPassword"
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    value={confirmNewPassword}
                    className="form-control special_styling"
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="form-group">
            <button type="submit" className="btn btn-primary btn-block">
              {token ? "Update Password" : "Reset Password"}
            </button>
          </div>
          {isError && (
            <div className="alert alert-danger" role="alert">
              {isError}
            </div>
          )}
        </form>
      ) : (
        <div className="alert alert-success" role="alert">
          {isSuccess}
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
