import React, { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import { bufferToDataURL } from "../utils/bufferToDataURL";
import moment from "moment";
import { truncateText } from "../utils/truncateText";
import { getAllProducts } from "../api/functions/products";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faStar } from "@fortawesome/free-solid-svg-icons";
import Downloads from "../components/common/downloads";
import { Bar } from "react-chartjs-2";
import { getAllReviews } from "../api/functions/reviews";
import Gallery from "react-photo-album";
import { getAllPortfolioImages } from "../api/functions/portfolio";
import showToast from "../components/common/showToast";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Home = () => {
  const {
    products,
    setProducts: setProductsContext,
    reviews,
    setReviews: setReviewsContext,
    portfolioImages,
    setPortfolioImages: setPortfolioImagesContext,
  } = useContext(AppContext);

  // const fetchData = async () => {
  //   try {
  //     const productsRes = await getAllProducts();
  //     if (productsRes.status === 200) {
  //       setProductsContext(productsRes.data.products);
  //     }
  //     const reviewsRes = await getAllReviews();
  //     if (reviewsRes.status === 200) {
  //       setReviewsContext(reviewsRes.data.reviews);
  //     }
  //     const portfolioRes = await getAllPortfolioImages();
  //     if (portfolioRes.status === 200) {
  //       setPortfolioImagesContext(portfolioRes.data.images);
  //     }
  //   } catch (error) {
  //     showToast(error?.response?.data?.message, "error");
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const dailyReviewCount = reviews.reduce((acc, review) => {
    const dayKey = moment(review.createdAt).format("YYYY-MM-DD");
    acc[dayKey] = (acc[dayKey] || 0) + 1;
    return acc;
  }, {});

  const chartData = {
    labels: Object.keys(dailyReviewCount),
    datasets: [
      {
        label: "Reviews per Day",
        data: Object.values(dailyReviewCount),
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const convertImagesForGallery = () => {
    return portfolioImages.slice(0, 4).map((image) => ({
      src: `${IMAGE_URL}/image/${image?.image}`,
      width: 5,
      height: 3,
    }));
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12 rounded">
          <nav className="navbar-breadcrumb rounded-2 col-xl-12 col-12 d-flex flex-row p-0 mt-0">
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-start">
              <ul className="navbar-nav mr-lg-2">
                <li className="nav-item ms-0">
                  <h4 className="mb-0">Dashboard</h4>
                </li>
                <li className="nav-item">
                  <div className="d-flex align-items-center">
                    <p className="mb-0">Home</p>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="px-2"
                      fontSize={12}
                    />
                    <p className="mb-0">Main Dashboard</p>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="mb-3">
                <h5 className="card-title">Reviews List</h5>
              </div>
              <div className="table-responsive">
                {reviews?.length > 0 ? (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Content</th>
                        <th>Stars</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reviews.slice(0, 3).map((review, index) => (
                        <tr key={review._id}>
                          <td>{index + 1}</td>
                          <td>{review.clientName}</td>
                          <td>{truncateText(review.description.en, 100)}</td>
                          <td>
                            {Array.from({ length: review.stars }, (_, i) => (
                              <FontAwesomeIcon
                                key={i}
                                icon={faStar}
                                style={{ color: "#E1A342" }}
                              />
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Downloads />
                )}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="mb-3">
                <h5 className="card-title">Products List</h5>
              </div>
              {products.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Description</th>
                        <th>Last updated</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.slice(0, 3).map((product, index) => (
                        <tr key={product._id}>
                          <td>{index + 1}</td>
                          <td className="d-flex align-items-center gap-3">
                            {product?.image && (
                              <img
                                src={`${IMAGE_URL}/image/${product?.image}`}
                                alt={`Product ${product._id}`}
                                style={{
                                  width: 50,
                                  height: 50,
                                  objectFit: "cover",
                                }}
                              />
                            )}
                            <strong>{product?.title?.en}</strong>
                          </td>
                          <td>
                            {product.price === 0
                              ? "The price not selected"
                              : product.price + " DH"}
                          </td>
                          <td>{truncateText(product.description.en, 31)}</td>
                          <td>{moment(product?.updatedAt).fromNow()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Downloads />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="mb-3">
                <h5 className="card-title">Portfolio gallery</h5>
              </div>
              {portfolioImages.length > 0 ? (
                <Gallery layout="rows" photos={convertImagesForGallery()} />
              ) : (
                <Downloads />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
