import React, { useState } from "react";
import { createPortfolio } from "../../api/functions/portfolio";
import uploadIcon from "../../assets/images/icons/upload.svg";
import Gallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";
import showToast from "../common/showToast";

const NewImage = ({ fetchImages }) => {
  const [images, setImages] = useState([]);
  const [imageNames, setImageNames] = useState([]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files) {
      const selectedImages = Array.from(files);
      setImages(selectedImages);
      setImageNames(selectedImages.map((file) => file.name));
    }
  };

  const resetData = () => {
    setImages([]);
    setImageNames([]);
  };

  const uploadImage = async (image) => {
    const formImage = new FormData();
    formImage.append("image", image);

    try {
      const res = await createPortfolio(formImage);

      if (res.status === 201) {
        showToast(res.data.message.en, "info");
      }
    } catch (err) {
      showToast(err?.response?.data?.message, "error");
    }
  };

  const createNewImage = async (e) => {
    e.preventDefault();

    for (const image of images) {
      await uploadImage(image);
    }

    resetData();
    fetchImages();
  };

  return (
    <form onSubmit={createNewImage} className="image-upload-form">
      {images.length > 0 ? (
        <Gallery
          items={images.map((file) => ({
            original: URL.createObjectURL(file),
            thumbnail: URL.createObjectURL(file),
            description: file.name,
          }))}
        />
      ) : (
        <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
          <input
            className="file-area__input"
            type="file"
            accept="image/*"
            id="uploader-product-presentational-media-input-1"
            multiple={true}
            onChange={handleFileChange}
          />
          <label
            className="file-area__label"
            htmlFor="uploader-product-presentational-media-input-1"
          >
            <span className="uploader-product-media-file-area__label">
              <img src={uploadIcon} alt="" />
              <strong className="t3">
                {imageNames.length > 0
                  ? imageNames.join(", ")
                  : "Upload presentational images"}
              </strong>
            </span>
          </label>
        </div>
      )}
      <div className="UploaderProductUploadSection__description t3">
        JPG, PNG formats only. File under 10MB. The main image must have an 8:5
        aspect ratio.
      </div>

      <div className="mt-4">
        <button type="submit" className="btn btn-primary me-2">
          Upload Images
        </button>
        <button type="reset" onClick={resetData} className="btn btn-light">
          Cancel
        </button>
      </div>
    </form>
  );
};

export default NewImage;
