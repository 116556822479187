import React, { useContext, useEffect, useState } from "react";
import ReviewForm from "../components/review/ReviewForm";
import { getAllReviews, getReviewById } from "../api/functions/reviews";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import DeleteReview from "../components/review/DeleteReview";
import EditReview from "../components/review/EditReview";
import { Bar } from "react-chartjs-2";
import { Chart, BarElement } from "chart.js";
import { AppContext } from "../context/AppContext";
import { truncateText } from "../utils/truncateText";
import moment from "moment";
import { BarChart } from "../charts/BarChart";
import Downloads from "../components/common/downloads";
import showToast from "../components/common/showToast";
Chart.register(BarElement);

const Reviews = () => {
  const { reviews, setReviews: setReviewsContext } = useContext(AppContext);

  const [reviewObject, setReviewObject] = useState({});

  const getAllReviewsData = async () => {
    try {
      const response = await getAllReviews();
      if (response.status === 200) {
        setReviewsContext(response.data.reviews);
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  useEffect(() => {
    getAllReviewsData();
  }, []);

  const getReviewObjectById = async (e, id) => {
    e.preventDefault();

    try {
      const res = await getReviewById(id);
      if (res.status === 200) {
        setReviewObject(res.data.review);
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  const dailyReviewCount = reviews.reduce((acc, review) => {
    const dayKey = moment(review.createdAt).format("YYYY-MM-DD");
    acc[dayKey] = (acc[dayKey] || 0) + 1;
    return acc;
  }, {});

  const chartData = {
    labels: Object.keys(dailyReviewCount),
    datasets: [
      {
        label: "Reviews per Day",
        data: Object.values(dailyReviewCount),
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <DeleteReview review={reviewObject} fetchReviews={getAllReviewsData} />
      <EditReview review={reviewObject} fetchReviews={getAllReviewsData} />

      <div className="row mb-4">
        <div className="col-xl-7 col-md-12 mb-4">
          <div className="card">
            <div className="card-body">
              <ReviewForm fetchReviews={getAllReviewsData} />
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-md-12">
          <div className="card">
            <div className="card-body h-500">
              <BarChart chartData={chartData} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                {reviews?.length > 0 ? (
                  <table className="table table-striped mg-b-0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Content</th>
                        <th>Stars</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reviews.map((review, index) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{review.clientName}</td>
                          <td>{truncateText(review.description.en, 100)}</td>
                          <td>
                            {Array.from({ length: review.stars }, (_, i) => (
                              <FontAwesomeIcon
                                key={i}
                                icon={faStar}
                                style={{ color: "#E1A342" }}
                              />
                            ))}
                          </td>
                          <td className="">
                            <button
                              className="btn btn-success me-2"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#editReviewModel"
                              onClick={(e) =>
                                getReviewObjectById(e, review?._id)
                              }
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button
                              className="btn btn-danger"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteReviewModal"
                              onClick={(e) =>
                                getReviewObjectById(e, review?._id)
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Downloads />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reviews;
