import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Login } from "../../api/functions/auth";

const LoginForm = () => {
  const { setToken } = useContext(AppContext);
  const [isError, setIsError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Set default values for username and password
  const [defaultValues] = useState({
    username: "achraf.lafkiri.2@gmail.com",
    password: "123",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const values = {};
    formData.forEach((value, key) => {
      values[key] = value;
    });

    // Use a simple regular expression to check if the input is an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(values.emailOrUsername)) {
      // If it's an email
      const valuesToSend = {
        email: values.emailOrUsername,
        password: values.password,
      };

      try {
        const response = await Login(valuesToSend);
        setToken(response?.data?.token);
      } catch (err) {
        setIsError(err?.response?.data?.message);
        if (err?.response) {
          setTimeout(() => {
            setIsError("");
          }, 3000);
        }
      }
    } else {
      const valuesToSend = {
        username: values.emailOrUsername,
        password: values.password,
      };

      try {
        const response = await Login(valuesToSend);

        setToken(response.data.token);
      } catch (err) {
        setIsError(err.response?.data?.error?.message);

        if (err.response) {
          setTimeout(() => {
            setIsError("");
          }, 3000);
        }
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="forms-sample">
        <div className="form-group">
          <label htmlFor="emailOrUsername">Email or Username</label>
          <input
            type="text"
            name="emailOrUsername"
            id="emailOrUsername"
            className="form-control special_styling"
            defaultValue={defaultValues.username}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              className="form-control special_styling"
              defaultValue={defaultValues.password}
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="form-group">
          <button type="submit" className="btn btn-primary btn-block">
            Send
          </button>
        </div>
        <div className="form-group">
          <Link className="btn-link bg-light" to={"/account/reset-password"}>
            Forgot Password?
          </Link>
        </div>
        {isError ? (
          <div className="alert alert-danger" role="alert">
            {isError}
          </div>
        ) : null}
      </form>
    </>
  );
};

export default LoginForm;
