import React, { useContext, useEffect, useState } from "react";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import DeleteProduct from "./DeleteProduct";
import { getAllProducts, getProductById } from "../../api/functions/products";
import EditProductModel from "./EditProductModel";
import { AppContext } from "../../context/AppContext";
import { truncateText } from "../../utils/truncateText";
import { bufferToDataURL } from "../../utils/bufferToDataURL";
import showToast from "../common/showToast";
import Downloads from "../common/downloads";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const ProductsTable = () => {
  const { products, setProducts: setProductsContext } = useContext(AppContext);

  const [productObject, setProductObject] = useState();

  const getAllProductsData = async () => {
    try {
      const response = await getAllProducts();
      if (response.status === 200) {
        setProductsContext(response.data.products);
      }
    } catch (error) {
      showToast("Error fetching products", "error");
    }
  };

  const getProductObject = async (e, id) => {
    e.preventDefault();

    try {
      const res = await getProductById(id);
      if (res.status === 200) {
        setProductObject(res.data.product);
      }
    } catch (error) {
      showToast(error.response?.data?.message, "error");
    }
  };

  return (
    <>
      <DeleteProduct
        product={productObject}
        fetchProducts={getAllProductsData}
      />
      <EditProductModel
        product={productObject}
        fetchProducts={getAllProductsData}
      />

      {products.length > 0 ? (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Price</th>
              <th>Description</th>
              <th>Last updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={product._id}>
                <td>{index + 1}</td>
                <td className="d-flex align-items-center gap-3">
                  {product?.image && (
                    <img
                      src={`${IMAGE_URL}/image/${product.image}`}
                      alt={`Product ${product._id}`}
                      style={{
                        width: 50,
                        height: 50,
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <strong>{product?.title?.en}</strong>
                </td>
                <td>{product.price} DH</td>
                <td>{truncateText(product.description.en, 100)}</td>
                <td>{moment(product?.updatedAt).fromNow()}</td>
                <td>
                  <button
                    className="btn btn-success mr-2"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#editProduct"
                    onClick={(e) => getProductObject(e, product._id)}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </button>

                  <button
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteProduct"
                    onClick={(e) => getProductObject(e, product._id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Downloads />
      )}
    </>
  );
};

export default ProductsTable;
