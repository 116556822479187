import React, { useContext, useEffect, useState } from "react";
import { getAllSlideItems, getSlideById } from "../../api/functions/heroSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../context/AppContext";
import Downloads from "../common/downloads";
import SwiperDelete from "./SwiperDelete";
import SwiperEdit from "./SwiperEdit";

const SwiperTable = ({ fetchSlidesData }) => {
  const { sliders } = useContext(AppContext);

  const [sliderObject, setSliderObject] = useState({});

  const getSlideObjectById = async (e, id) => {
    e.preventDefault();

    const res = await getSlideById(id);
    if (res.status === 200) {
      setSliderObject(res.data.slide);
    }
  };

  useEffect(() => {
    fetchSlidesData();
  }, []);

  return (
    <>
      <SwiperDelete swiper={sliderObject} fetchSlides={fetchSlidesData} />
      <SwiperEdit swiper={sliderObject} fetchSlides={fetchSlidesData} />

      <div className="heroSlider">
        {sliders?.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Subtitle</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {sliders?.map((slide) => (
                  <tr key={slide._id}>
                    <td>{slide.title.en}</td>
                    <td>
                      {slide.subtitle.en?.length > 10
                        ? `${slide.subtitle.en.substring(0, 10)}...`
                        : slide.subtitle.en}
                    </td>
                    <td>
                      <button
                        className="btn btn-success me-2"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editSwiper"
                        onClick={(e) => getSlideObjectById(e, slide._id)}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                      <button
                        className="btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteSwiper"
                        onClick={(e) => getSlideObjectById(e, slide._id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <Downloads />
        )}
      </div>
    </>
  );
};

export default SwiperTable;
