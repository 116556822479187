import api from "../http-service";

export const Login = (userData) => api.post("auth/login", userData);

export const forgotPassword = (email) =>
  api.post("auth/forgot-password", email);

export const updatePassword = (data, token) =>
  api.patch(`auth/update-password?token=${token}`, data);

export const updateUserInfo = (data) => api.patch("auth/update-me", data);

export const getUserInfo = async () => {
  const response = await api.get("auth/user");
  return response;
};
