import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { updateUserInfo } from "../api/functions/auth";
import { AppContext } from "../context/AppContext";
import showToast from "../components/common/showToast";

const Profile = () => {
  const { userData } = useContext(AppContext);

  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: null,
    oldPassword: null,
    avatar: null,
  });

  useEffect(() => {
    setFormData({
      username: userData?.username || "",
      email: userData?.email || "",
      password: "",
      oldPassword: "",
      avatar: userData?.avatar,
    });
  }, [userData]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    if (name === "password" && !showOldPassword && value !== "") {
      setShowOldPassword(true);
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    setSelectedImageFile(file);
    setFormData((prevState) => ({
      ...prevState,
      avatar: file,
    }));

    setShowModal(true);

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      const previewImage = document.getElementById("modal-preview-image");
      if (previewImage) {
        previewImage.src = imageUrl;
      }
    }
  };

  const handleSaveInfos = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("username", formData.username);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("password", formData.password);
    formDataToSend.append("oldPassword", formData.oldPassword);
    formDataToSend.append("avatar", selectedImageFile);

    try {
      const res = await updateUserInfo(formDataToSend);
      if (res.status === 200) {
        showToast(res.data.message.en, "success");
        // Reset password fields after successful update
        setFormData({
          ...formData,
          password: "",
          oldPassword: "",
        });
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const savePhoto = async () => {
    const formData = new FormData();
    formData.append("avatar", selectedImageFile);

    try {
      const res = await updateUserInfo(formData);
      if (res.status === 200) {
        showToast(res.data.message.en, "success");
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div id="profile">
      <div className="row ">
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSaveInfos}>
              <div className="row mb-4">
                <div className="col-lg-5 col-md-12">
                  <div className="form-input mb-4">
                    <label className="labeltest" htmlFor="file-ip-1">
                      <span> Drop image here or click to upload. </span>
                    </label>
                    {selectedImageFile && (
                      <div className="preview">
                        <img id="file-ip-1-preview" src="" alt="img" />
                      </div>
                    )}
                    <input
                      type="file"
                      id="file-ip-1"
                      accept="image/*"
                      onChange={handleFile}
                    />
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      name="username"
                      placeholder="Jhon Doe"
                      className="form-control"
                      id="username"
                      value={formData.username || ""}
                      onChange={handleChanges}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="email"
                      placeholder="example@example.com"
                      className="form-control"
                      id="email"
                      value={formData.email || ""}
                      onChange={handleChanges}
                      required
                    />
                  </div>
                  {showOldPassword && (
                    <div className="form-group">
                      <div className="input-group mb-2">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="oldPassword"
                          id="oldPassword"
                          placeholder="Enter old password"
                          className="form-control special_styling"
                          value={formData.oldPassword || ""}
                          onChange={handleChanges}
                        />

                        <div className="input-group-append">
                          <span
                            className="input-group-text"
                            onClick={toggleOldPasswordVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            {showPassword ? (
                              <FontAwesomeIcon icon={faEyeSlash} />
                            ) : (
                              <FontAwesomeIcon icon={faEye} />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <div className="input-group mb-2">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="Enter new password"
                        className="form-control special_styling"
                        value={formData.password || ""}
                        onChange={handleChanges}
                      />

                      <div className="input-group-append">
                        <span
                          className="input-group-text"
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </span>
                      </div>
                    </div>
                    <small>
                      Strong password requirements: 8+ symbols, combination of
                      uppercase and lowercase latin letters, numbers, and
                      special characters.
                    </small>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className={`modal ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Selected Image</h5>
              <button
                type="button"
                className="close"
                onClick={closeModal}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img id="modal-preview-image" src="" alt="Selected" />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  savePhoto();
                  closeModal();
                }}
              >
                Save photo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
