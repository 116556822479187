import React from "react";
import Image404 from "../assets/images/404.jpg";

const Page404 = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        height: "100vh",
      }}
    >
      <img
        style={{
          width: "500px",
        }}
        src={Image404}
        alt=""
      />
    </div>
  );
};

export default Page404;
