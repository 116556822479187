import React from "react";
import { deleteProductById } from "../../api/functions/products";
import { toast } from "react-toastify";
import showToast from "../common/showToast";

const DeleteProduct = ({ product, fetchProducts }) => {
  const handleSaveChanges = async () => {
    try {
      const response = await deleteProductById(product._id);

      if (response.status === 204) {
        fetchProducts();
        showToast("Product has been deleted successfully", "info");
      }
    } catch (error) {
      showToast(`${error.response?.data?.message}`, "error");
    }
  };

  return (
    <div
      className="modal fade"
      id="deleteProduct"
      tabIndex="-1"
      aria-labelledby="deleteProductLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deleteProductLabel">
              Confirm Deletion
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              Are you sure you want to delete the product "{product?.title.en}
              "?
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleSaveChanges}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteProduct;
