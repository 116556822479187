import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { AppContext } from "../context/AppContext";
import { getAllCompanies, getCompanyById } from "../api/functions/company";
import showToast from "../components/common/showToast";
import CompanyForm from "../components/company/CompanyForm";
import CompanyTable from "../components/company/CompanyTable";
import EditCompany from "../components/company/EditCompany";
import DeleteCompany from "../components/company/DeleteCompany";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Company = () => {
  const { companies, setCompanies: setCompaniesContext } =
    useContext(AppContext);

  const getAllCompaniesData = async () => {
    try {
      const res = await getAllCompanies();
      if (res.status === 200) {
        setCompaniesContext(res.data.companies);
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  useEffect(() => {
    getAllCompaniesData();
  }, []);

  const [companyObject, setCompanyObject] = useState({});

  const getCompanyObject = async (e, id) => {
    e.preventDefault();
    try {
      const res = await getCompanyById(id);
      if (res.status === 200) {
        setCompanyObject(res.data.company);
      }
    } catch (err) {
      showToast(err?.response?.data?.message, "error");
    }
  };

  return (
    <>
      <EditCompany
        company={companyObject}
        fetchCompanies={getAllCompaniesData}
      />
      <DeleteCompany
        company={companyObject}
        fetchCompanies={getAllCompaniesData}
      />

      <div className="row mb-3">
        <div className="col-xl-6 col-md-12 mb-4">
          <div className="card">
            <div className="card-body">
              <CompanyForm fetchCompanies={getAllCompaniesData} />
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <CompanyTable
                companies={companies}
                getCompanyObject={getCompanyObject}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Company;
