import React, { useState } from "react";
import { editWebsiteInfo } from "../../api/functions/websiteInfo";
import showToast from "../common/showToast";
import uploadIcon from "../../assets/images/icons/upload.svg";

const WebsiteInfoForm = () => {
  const [selectedlogoImageFile, setSelectedlogoImageFile] = useState(null);
  const [selectedMiniLogoImageFile, setSelectedMiniLogoImageFile] =
    useState(null);
  const [selectedFaviconImageFile, setSelectedFaviconImageFile] =
    useState(null);
  const [navbarLogoName, setNavbarLogoName] = useState("");
  const [miniLogo, setMiniLogo] = useState("");
  const [faviconName, setFaviconName] = useState("");

  const [formData, setFormData] = useState({
    description: "",
    address: "",
    phone: "",
    mail: "",
    logoImage: null,
    miniLogoImage: null,
    faviconImage: null,
    mapUrl: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (fieldName, file) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: file,
    }));

    switch (fieldName) {
      case "logoImage":
        setSelectedlogoImageFile(file);
        setNavbarLogoName(file.name);
        break;
      case "miniLogoImage":
        setSelectedMiniLogoImageFile(file);
        setMiniLogo(file.name);
        break;
      case "faviconImage":
        setSelectedFaviconImageFile(file);
        setFaviconName(file.name);
        break;
      default:
        break;
    }
  };

  const resetData = () => {
    setFormData({
      description: "",
      address: "",
      phone: "",
      mail: "",
      logoImage: null,
      miniLogoImage: null,
      faviconImage: null,
      mapUrl: "", // Reset mapUrl field
    });

    setNavbarLogoName("");
    setMiniLogo("");
    setFaviconName("");
  };

  const handleEditWebsiteInfos = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (value instanceof File) {
          formDataToSend.append(key, value);
        } else {
          formDataToSend.append(key, value);
        }
      });

      const res = await editWebsiteInfo(formDataToSend);

      if (res.status === 200) {
        showToast(res.data.message.en, "success");
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  return (
    <form onSubmit={handleEditWebsiteInfos}>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="uploadNavbarLogo">Upload New Navbar Logo</label>
            {/* Handle displaying selected logo image */}
            {selectedlogoImageFile ? (
              <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                <img
                  src={URL.createObjectURL(selectedlogoImageFile)}
                  alt="Selected"
                  className="mt-2"
                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                />
                <input
                  className="file-area__input"
                  type="file"
                  accept="image"
                  id="uploadNavbarLogo"
                  onChange={(e) =>
                    handleFileChange("logoImage", e.target.files[0])
                  }
                />
              </div>
            ) : (
              <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                <input
                  className="file-area__input"
                  type="file"
                  accept="image"
                  id="uploadNavbarLogo"
                  onChange={(e) =>
                    handleFileChange("logoImage", e.target.files[0])
                  }
                />
                <label
                  className="file-area__label"
                  htmlFor="uploader-product-presentational-media-input-1"
                >
                  <span className="uploader-product-media-file-area__label">
                    <img src={uploadIcon} alt="" />
                    <strong className="t3">
                      {navbarLogoName
                        ? navbarLogoName
                        : "Upload presentational image"}
                    </strong>
                  </span>
                </label>
              </div>
            )}
            <div className="UploaderProductUploadSection__description t3 ">
              JPG, PNG formats only. File under 10MB. The main image must have
              an 8:5 aspect ratio, the minimum size of 336 x 350 px.
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="MiniLogoImage">Upload New Mini Logo Image</label>
            {selectedMiniLogoImageFile ? (
              <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                <img
                  src={URL.createObjectURL(selectedMiniLogoImageFile)}
                  alt="Selected"
                  className="mt-2"
                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                />
                <input
                  className="file-area__input"
                  type="file"
                  accept="image"
                  id="MiniLogoImage"
                  onChange={(e) =>
                    handleFileChange("logoImage", e.target.files[0])
                  }
                />
              </div>
            ) : (
              <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                <input
                  className="file-area__input"
                  type="file"
                  accept="image"
                  id="MiniLogoImage"
                  onChange={(e) =>
                    handleFileChange("miniLogoImage", e.target.files[0])
                  }
                />
                <label className="file-area__label" htmlFor="">
                  <span className="uploader-product-media-file-area__label">
                    <img src={uploadIcon} alt="" />
                    <strong className="t3">
                      {miniLogo ? miniLogo : "Upload presentational image"}
                    </strong>
                  </span>
                </label>
              </div>
            )}
            <div className="UploaderProductUploadSection__description t3 ">
              JPG, PNG formats only. File under 10MB. The main image must have
              an 8:5 aspect ratio, the minimum size of 336 x 350 px.
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="faviconimage">Upload New Fav icon</label>
            {selectedFaviconImageFile ? (
              <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                <img
                  src={URL.createObjectURL(selectedFaviconImageFile)}
                  alt="Selected"
                  className="mt-2"
                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                />
                <input
                  className="file-area__input"
                  type="file"
                  accept="image"
                  id="faviconimage"
                  onChange={(e) =>
                    handleFileChange("logoImage", e.target.files[0])
                  }
                />
              </div>
            ) : (
              <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                <input
                  className="file-area__input"
                  type="file"
                  accept="image"
                  id="faviconImage"
                  onChange={(e) =>
                    handleFileChange("faviconImage", e.target.files[0])
                  }
                />
                <label className="file-area__label" htmlFor="faviconimage">
                  <span className="uploader-product-media-file-area__label">
                    <img src={uploadIcon} alt="" />
                    <strong className="t3">
                      {faviconName
                        ? faviconName
                        : "Upload presentational image"}
                    </strong>
                  </span>
                </label>
              </div>
            )}
            <div className="UploaderProductUploadSection__description t3 ">
              JPG, PNG formats only. File under 10MB.
            </div>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="address">Address</label>
        <input
          type="text"
          className="form-control"
          placeholder="Address"
          name="address"
          id="address"
          value={formData.address}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="phone">Phone</label>
        <input
          type="text"
          className="form-control"
          placeholder="21212344567"
          name="phone"
          id="phone"
          value={formData.phone}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="mail">Email</label>
        <input
          type="text"
          className="form-control"
          placeholder="example@domain.com"
          name="mail"
          id="mail"
          value={formData.mail}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="mapUrl">Map URL</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter map URL"
          name="mapUrl"
          value={formData.mapUrl}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea
          rows="3"
          className="form-control"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Description"
        ></textarea>
      </div>

      <button type="submit" className="btn btn-primary">
        Submit
      </button>
      <button type="reset" className="btn btn-light ml-2" onClick={resetData}>
        Reset
      </button>
    </form>
  );
};

export default WebsiteInfoForm;
