import React, { useEffect, useState } from "react";
import { updateCategory } from "../../api/functions/categories";
import showToast from "../common/showToast";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const EditCategoryModel = ({ category, fetchAllCategories }) => {
  const [imageName, setImageName] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [formCategory, setFormCategory] = useState({
    categoryName: "",
    categoryImage: null,
  });

  useEffect(() => {
    setFormCategory({
      categoryName: category?.name?.en,
      categoryImage: category?.image,
    });
  }, [category]);

  const handleInputChange = ({ target }) => {
    setFormCategory((prevState) => ({
      ...prevState,
      categoryName: target.value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageName(file.name);
      setSelectedImageFile(file);
      setFormCategory((prevState) => ({
        ...prevState,
        categoryImage: file,
      }));
    }
  };

  const handleReset = () => {
    setImageName(null);
    setFormCategory({
      categoryName: "",
      categoryImage: null,
    });
  };

  const handleEdit = async (e, id) => {
    e.preventDefault();

    const formCategoryToSend = new FormData();
    formCategoryToSend.append("name", formCategory?.categoryName);
    formCategoryToSend.append("image", formCategory?.categoryImage);

    try {
      const res = await updateCategory(id, formCategoryToSend);
      if (res.status === 200) {
        fetchAllCategories();
        showToast(res.data.message.en, "success");
      }
    } catch (err) {
      showToast(err.response.data.message, "error");
    }
  };

  return (
    <div
      className="modal fade"
      id="editCategory"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="editCategoryLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editCategoryLabel">
              Edit {category?.name?.en} category
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={(e) => handleEdit(e, category._id)}>
            <div className="modal-body">
              <div className="form-group">
                {selectedImageFile ? (
                  <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                    <img
                      src={URL.createObjectURL(selectedImageFile)}
                      alt="Selected"
                      className="mt-2"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                    <input
                      className="file-area__input"
                      id="uploader-product-presentational-media-input-0"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </div>
                ) : (
                  <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                    <label
                      className="file-area__label"
                      htmlFor="uploader-product-presentational-media-input-0"
                    >
                      <input
                        className="file-area__input"
                        id="uploader-product-presentational-media-input-0"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                      <img
                        src={`${IMAGE_URL}/image/${category?.image}`}
                        alt="Selected"
                        className="mt-2"
                        style={{ maxWidth: "100%", maxHeight: "150px" }}
                      />
                    </label>
                  </div>
                )}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  required
                  value={formCategory.categoryName || ""}
                  className="form-control"
                  placeholder="e.g., Salon"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Update category
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditCategoryModel;
