import React from "react";
import { deleteSliderById } from "../../api/functions/heroSlider";
import showToast from "../common/showToast";

const SwiperDelete = ({ swiper, fetchSlides }) => {
  const handleDeleteSwiper = async (e, id) => {
    e.preventDefault();

    try {
      const res = await deleteSliderById(id);
      if (res.status === 204) {
        fetchSlides();
        showToast("The Slider Has been deleted successfully", "info");
      }
    } catch (err) {
      showToast(err?.response?.data?.message, "error");
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="deleteSwiper"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteSwiperLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteSwiperLabel">
                Delete Swiper
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this swiper?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={(e) => handleDeleteSwiper(e, swiper._id)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwiperDelete;
