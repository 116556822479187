import React, { useState } from "react";
import { createSlideItem } from "../../api/functions/heroSlider";
import uploadIcon from "../../assets/images/icons/upload.svg";
import showToast from "../common/showToast";

const NewSlide = ({ fetchSlidesData }) => {
  const [imageName, setImageName] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const [slideFormData, setSlideFormData] = useState({
    title: "",
    subtitle: "",
    button: "",
    image: null,
  });

  const handleReset = () => {
    setImageName("");
    setSelectedImageFile(null);
    setSlideFormData({
      title: "",
      subtitle: "",
      button: "",
      image: null,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImageFile(file);
      setImageName(file.name);
      setSlideFormData((prevState) => ({
        ...prevState,
        image: file,
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSlideFormData({
      ...slideFormData,
      [name]: value,
    });
  };

  const handleCreateSlide = async (e) => {
    e.preventDefault();

    try {
      const slideFormDataToSend = new FormData();
      slideFormDataToSend.append("title", slideFormData.title);
      slideFormDataToSend.append("subtitle", slideFormData.subtitle);
      slideFormDataToSend.append("button", slideFormData.button);
      slideFormDataToSend.append("image", selectedImageFile);

      const res = await createSlideItem(slideFormDataToSend);
      if (res.status === 201) {
        showToast(res.data.message.en, "info");
        fetchSlidesData();
        handleReset();
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  return (
    <form onSubmit={handleCreateSlide} className="forms-sample">
      <div className="form-group">
        <input
          className="form-control"
          placeholder="Enter Title"
          type="text"
          name="title"
          value={slideFormData.title}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <textarea
          rows="3"
          className="form-control"
          name="subtitle"
          value={slideFormData.subtitle}
          onChange={handleInputChange}
          placeholder="Enter description"
          required
        ></textarea>
      </div>
      <div className="form-group">
        <input
          className="form-control"
          placeholder="Enter Button"
          type="text"
          name="button"
          value={slideFormData.button}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        {/* File Upload Section */}
        {selectedImageFile ? (
          <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
            <img
              src={URL.createObjectURL(selectedImageFile)}
              alt="Selected"
              className="mt-2"
              style={{ maxWidth: "100%", maxHeight: "150px" }}
            />
            <input
              className="file-area__input"
              type="file"
              accept="image"
              id="uploadSliderImage"
              multiple=""
              onChange={handleFileChange}
            />
          </div>
        ) : (
          <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
            <input
              className="file-area__input"
              type="file"
              accept="image"
              id="uploadSliderImage"
              multiple=""
              onChange={handleFileChange}
            />
            <label className="file-area__label" htmlFor="uploadSliderImage">
              <span className="uploader-product-media-file-area__label">
                <img src={uploadIcon} alt="" />
                <strong className="t3">
                  {imageName ? imageName : "Upload presentational image"}
                </strong>
              </span>
            </label>
          </div>
        )}
        <div className="UploaderProductUploadSection__description t3 ">
          JPG, PNG formats only. File under 10MB. The main image must have an
          8:5 aspect ratio, the minimum size of 1920 x 1080 px. Main information
          about your item should be placed in the center of an image.
        </div>
      </div>
      <button className="btn btn-primary">Add Slider</button>
      <button className="btn btn-light ml-2" type="reset" onClick={handleReset}>
        Cancel
      </button>
    </form>
  );
};

export default NewSlide;
