import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBox,
  faStar,
  faBuilding,
  faCog,
  faImage,
  faPerson,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const navItem = [
  {
    page: "Dashboard",
    iconName: faHome,
    path: "/dashboard",
  },
  {
    page: "Products",
    iconName: faBox,
    path: "/products",
  },
  {
    page: "Portfolio",
    iconName: faImage,
    path: "/portfolio",
  },
  {
    page: "Reviews",
    iconName: faStar,
    path: "/reviews",
  },
  {
    page: "Companies",
    iconName: faBuilding,
    path: "/companies",
  },
  {
    page: "Profile",
    iconName: faUser,
    path: "/profile",
    badgeContent: true,
  },
  {
    page: "Settings",
    iconName: faCog,
    path: "/settings",
    badgeContent: true,
  },
];

const Sidebar = () => {
  const [isActiveNavItem, setIsActiveNavItem] = useState(false);

  const onClickNavItem = () => {
    setIsActiveNavItem(!isActiveNavItem);
  };

  const customeIcon = {
    fontSize: "1.215rem",
    lineHeight: 1,
    marginRight: "0.75rem",
    color: "#6c7293",
  };

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {navItem.map((item, index) => (
          <li className="nav-item" key={index}>
            <Link className="nav-link" to={item.path}>
              {item.iconName && (
                <FontAwesomeIcon
                  icon={item.iconName}
                  style={customeIcon}
                  className="menu-icon"
                />
              )}
              <span className="menu-title">{item.page}</span>
              {item.badgeContent && (
                <div className="badge badge-danger">new</div>
              )}
            </Link>
          </li>
        ))}
        {/* <li className="nav-item" onClick={onClickNavItem}>
          <Link
            className={`nav-link ${isActiveNavItem ? "collapsed" : ""}`}
            to="/ui-basic"
            data-toggle="collapse"
            aria-expanded={isActiveNavItem ? true : false}
            aria-controls="ui-basic"
          >
            <i className="typcn typcn-document-text menu-icon"></i>
            <span className="menu-title">UI Elements</span>
            <FontAwesomeIcon icon={faCaretDown} />
          </Link>
          <div
            className={`collapse ${isActiveNavItem ? "show" : ""}`}
            id="ui-basic"
          >
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className="nav-link" to="/ui-features/buttons">
                  Buttons
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/ui-features/dropdowns">
                  Dropdowns
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/ui-features/typography">
                  Typography
                </Link>
              </li>
            </ul>
          </div>
        </li> */}
      </ul>
    </nav>
  );
};

export default Sidebar;
