import React, { useContext, useEffect, useState } from "react";
import {
  getAllPortfolioImages,
  deletePortfolioImage,
} from "../api/functions/portfolio";
import NewImage from "../components/portfolio/NewImage";
import Downloads from "../components/common/downloads";
import { PhotoAlbum } from "react-photo-album";
import { AppContext } from "../context/AppContext";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import showToast from "../components/common/showToast";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Portfolio = () => {
  const { portfolioImages, setPortfolioImages: setPortfolioImagesContext } =
    useContext(AppContext);
  const [images, setImages] = useState([]);

  const fetchImages = async () => {
    try {
      const res = await getAllPortfolioImages();
      if (res.status === 200) {
        setPortfolioImagesContext(res.data.images);
        if (res.data.images) {
          setImages(
            res.data.images.map((image) => ({
              src: `${IMAGE_URL}/image/${image.image}`,
              width: 4,
              height: 3,
              id: image._id,
            }))
          );
        }
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  useEffect(() => {
    fetchImages();
  }, [portfolioImages]);

  const handleDelete = async (id) => {
    try {
      const res = await deletePortfolioImage(id);
      if (res.status === 204) {
        fetchImages();
        showToast(`The image has been deleted successfully!`, "success");
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  const renderPhoto = ({ photo, onDelete }) => (
    <div
      key={photo.id}
      className="card position-relative"
      style={{ width: "18rem", margin: "10px" }}
    >
      <img src={photo.src} alt={`Image ${photo.id}`} className="card-img-top" />
      <div
        className="btn btn-danger position-absolute top-0 end-0 mt-2 me-2"
        onClick={() => handleDelete(photo.id)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </div>
    </div>
  );

  return (
    <div className="row">
      <div className="col-md-12 mb-3">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Upload New Portfolio Image</h4>
            <p className="card-title">The images will display as a gallery</p>
            <NewImage fetchImages={fetchImages} />
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            {portfolioImages.length > 0 ? (
              <PhotoAlbum
                layout="rows"
                spacing={20}
                padding={20}
                targetRowHeight={200}
                photos={images}
                renderPhoto={renderPhoto}
              />
            ) : (
              <Downloads />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
