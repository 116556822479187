import React, { useEffect, useState } from "react";
import { updateCompany } from "../../api/functions/company";
import { bufferToDataURL } from "../../utils/bufferToDataURL";
import showToast from "../common/showToast";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const EditCompany = ({ company, fetchCompanies }) => {
  const [imageName, setImageName] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const [formData, setFormData] = useState({
    companyName: "",
    companyImage: null,
  });

  useEffect(() => {
    setFormData({
      companyName: company?.companyName,
      companyImage: company?.image,
    });
  }, [company]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImageFile(file);
      setImageName(file.name);
      setFormData((prevState) => ({
        ...prevState,
        companyImage: file,
      }));
    }
  };

  const resetFormData = () => {
    setImageName(null);
    setSelectedImageFile(null);
    setFormData({
      companyName: "",
      companyImage: null,
    });
  };

  const onSubmitCompany = async (e, id) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      formDataToSend.append("companyName", formData.companyName);
      formDataToSend.append("image", formData.companyImage);

      const res = await updateCompany(id, formDataToSend);

      if (res.status === 200) {
        fetchCompanies();
        showToast(res.data.message.en, "success");
        resetFormData();
      }
    } catch (error) {
      showToast(error.response?.data.message, "error");
    }
  };
  return (
    <div
      className="modal fade"
      id="editCompanyModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="editCompanyModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editCompanyModalLabel">
              Edit company
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form
              onSubmit={(e) => onSubmitCompany(e, company._id)}
              className="forms-sample"
            >
              {/* Company Form */}
              <div className="form-group">
                <label>
                  Company name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="companyName"
                  value={formData.companyName || ""}
                  onChange={handleInputChange}
                  maxLength={40}
                  required
                />
              </div>
              <div className="form-group">
                {/* File Upload Section */}
                {selectedImageFile ? (
                  <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                    <img
                      src={URL.createObjectURL(selectedImageFile)}
                      alt="Selected"
                      className="mt-2"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                    <input
                      className="file-area__input"
                      type="file"
                      accept="image"
                      id="uploadeditCompanyImage"
                      multiple=""
                      onChange={handleFileChange}
                    />
                  </div>
                ) : (
                  <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                    <input
                      className="file-area__input"
                      type="file"
                      accept="image"
                      id="uploadeditCompanyImage"
                      multiple=""
                      onChange={handleFileChange}
                    />
                    {company?.image && (
                      <img src={`${IMAGE_URL}/image/${company.image}`} alt="" />
                    )}
                  </div>
                )}
                <div className="UploaderProductUploadSection__description t3 ">
                  JPG, PNG formats only. File under 10MB. The main image must
                  have an 8:5 aspect ratio, the minimum size of 350 x 150 px.
                  Main information about your item should be placed in the
                  center of an image, it will look better in the ads. Please,
                  check our.
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  save changes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary ml-3"
                  onClick={resetFormData}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCompany;
