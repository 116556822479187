import React, { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import { getAllProducts } from "../api/functions/products";
import DeleteAllProductsModal from "../components/product/DeleteAllProductsModel"; // Corrected component name
import ProductForm from "../components/product/ProductForm";
import CategoryForm from "../components/category/CategoryForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Downloads from "../components/common/downloads";
import ProductsTable from "../components/product/ProductsTable";
import showToast from "../components/common/showToast";

const Products = () => {
  const { products, setProducts: setProductsContext } = useContext(AppContext);

  const getAllProductsData = async () => {
    try {
      const response = await getAllProducts();
      if (response.status === 200) {
        setProductsContext(response.data.products);
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  useEffect(() => {
    getAllProductsData();
  }, []); // Empty dependency array to run once on component mount

  return (
    <>
      <DeleteAllProductsModal fetchProducts={getAllProductsData} />
      <div className="row">
        {/* Products */}
        <div className="col-xl-8 col-md-12 grid-margin stretch-card mb-4">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Product</h4>
              <ProductForm fetchProducts={getAllProductsData} />
            </div>
          </div>
        </div>
        {/* Categories */}
        <div className="col-xl-4 col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add New Category</h4>
              <CategoryForm />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div>
                  <h4 className="card-title">Product Table</h4>
                  <p className="card-description">
                    Products table with list of products data
                  </p>
                </div>
                <div>
                  {products.length > 0 ? (
                    <button
                      className="btn btn-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteAllProductsModal"
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-2" />
                      Delete All Products
                    </button>
                  ) : (
                    <Downloads />
                  )}
                </div>
              </div>
              <div className="table-responsive">
                <ProductsTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
