import React from "react";
import ForgotPasswordForm from "../../components/auth/ForgotPassword";
import { Navigate, useParams } from "react-router-dom";

const ForgotPasswordPage = () => {
  const { token } = useParams();

  return (
    <div className="forms-section">
      <div className="forms-box">
        <ForgotPasswordForm token={token} />
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
