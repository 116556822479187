import React from "react";
import { deleteReview } from "../../api/functions/reviews";
import showToast from "../common/showToast";

const DeleteReview = ({ review, fetchReviews }) => {
  const handleDeleteReview = async (e, id) => {
    e.preventDefault();

    try {
      const res = await deleteReview(id);

      if (res.status === 204) {
        fetchReviews();
        showToast("Review has been deleted successfully!", "info");
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="deleteReviewModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteReviewModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteReviewModalLabel"></h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Delete the review of review of {review?.clientName}.
            </div>
            <div className="modal-footer">
              <form>
                <button
                  type="reset"
                  className="btn btn-secondary mr-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={(e) => handleDeleteReview(e, review?._id)}
                >
                  Delete review
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteReview;
