import React, { useContext, useEffect, useState } from "react";
import Home from "./pages";
import Products from "./pages/Products";
import Reviews from "./pages/Reviews";
import Company from "./pages/Company";
import Settings from "./pages/Settings";
import Portfolio from "./pages/Portfolio";

import DefaultLayout from "./layouts/DefaultLayout";
import GuestLayout from "./layouts/GuestLayout";

import Login from "./pages/auth/LoginPage";

import Page404 from "./pages/Page404";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage.jsx";
import { AppContext } from "./context/AppContext.js";
import Profile from "./pages/Profile.jsx";
import Loader from "./components/Loader/Loader.jsx";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import showToast from "./components/common/showToast.jsx";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const App = () => {
  const { websiteInfo, userData } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    const getFavicon = async () => {
      try {
        websiteInfo.forEach((info) => {
          const faviconImage = info.faviconImage;
          const faviconLink = document.getElementById("favicon");
          if (faviconLink) {
            faviconLink.href = `${IMAGE_URL}/image/${faviconImage}`;
          }
        });
      } catch (error) {
        showToast("Error fetching favicon icon", "error");
      }
    };

    getFavicon();
  }, [websiteInfo]);

  return isLoading ? (
    <Loader />
  ) : (
    <Router>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/companies" element={<Company />} />
          <Route path="/profile" element={<Profile userData={userData} />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route path="/account" element={<GuestLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="reset-password" element={<ForgotPasswordPage />} />
          <Route
            path="reset-password/:token"
            element={<ForgotPasswordPage />}
          />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
};

export default App;
