import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const CompanyTable = ({ companies: companiesList, getCompanyObject }) => {
  const customTableColumns = [
    "table-info",
    "table-warning",
    "table-danger",
    "table-success",
    "table-primary",
  ];

  return (
    <>
      <div className="table-responsive pt-3">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Company name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {companiesList?.length > 0 ? (
              companiesList.map((company, index) => (
                <tr
                  key={index + 1}
                  className={
                    customTableColumns[index % customTableColumns.length]
                  }
                >
                  <td>{index + 1}</td>
                  <td className="d-flex align-items-center gap-3">
                    {company?.image && (
                      <img
                        src={`${IMAGE_URL}/image/${company.image}`}
                        alt={`company ${company._id}`}
                        style={{
                          width: 50,
                          height: 50,
                          objectFit: "cover",
                        }}
                      />
                    )}
                    {company.companyName}
                  </td>
                  <td>
                    <button
                      className="btn btn-success mr-2"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#editCompanyModal"
                      onClick={(e) => getCompanyObject(e, company._id)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button
                      className="btn btn-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteCompanyModal"
                      onClick={(e) => getCompanyObject(e, company._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="py-3 text-center">
                  No companies available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CompanyTable;
