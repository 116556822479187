import React, { useContext, useEffect, useState } from "react";

import NewSlide from "../components/hero/NewSlide";
import SwiperTable from "../components/hero/SwiperTable";
import { getAllSlideItems } from "../api/functions/heroSlider";
import { AppContext } from "../context/AppContext";
import WebsiteInfoForm from "../components/websiteInfo/WebsiteInfoForm";
import Contacts from "../components/settings/Contacts";
import showToast from "../components/common/showToast";

const Settings = () => {
  const { setSliders: setSliderListContext, websiteInfo } =
    useContext(AppContext);

  const getAllSlidesData = async () => {
    try {
      const res = await getAllSlideItems();
      if (res.status === 200) {
        setSliderListContext(res.data.sliders);
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
    }
  };

  useEffect(() => {
    getAllSlidesData();
  }, []);

  return (
    <div className="settings az-content az-content-dashboard">
      <div className="container">
        <div className="row mb-4">
          <div className="col-xl-6 col-md-12 mb-4">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add new slide</h4>
                <p className="card-text">
                  Add a new slide to your homepage by filling out this form. You
                  can add images
                </p>
                <NewSlide fetchSlidesData={getAllSlidesData} />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="card">
              <div className="card-body">
                <SwiperTable fetchSlidesData={getAllSlidesData} />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Social Media Contacts</h4>
                <p>You can add your social media links here.</p>
                <Contacts />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title mb-4">Change Website Information</h4>
                <WebsiteInfoForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
